  @viewport{
    zoom: 1.0;
    width: device-width;
  }

  /* larger devices */
  @media screen and (min-width: 992px) and (max-width: 1366px) {

    body{
      width: 100vw;
    }

    /*p{
      font-size: 1.5em !important;
    }*/

    /* navigation menu styling */
    nav{
      width: 100vw;
    }

    .fresh-text {
      padding-top: 50px !important;
    }

    .hide-on-desktop{
      display: none !important;
    }

    .sm-flex{
      display:flex !important;
    }

   

  }

  @media screen and (min-width: 1367px) {
    body{
      width: 1366px;
    }

    .hide-on-desktop{
      display: none !important;
    }

    .sm-flex{
      display:flex !important;
    }
  }


  /*
  @media (max-width: 576px) {

  }
  */


  /* extra small to small devices, iPhones, iPads, some tablets */
  /* Medium devices (tablets, 768px and down)  */
  @media (max-width: 767.98px) {
    body{
      max-width: 100% !important;
    }

    p{
      font-size: 1.5em !important;
    }

    .logo{
      padding: 0 0;
    }

    .logo img{
      height:30px;
    }

    .img-fluid{
      min-width:100vw;
      /*max-height: 50vh;*/
      overflow: hidden;
    }

    .navi{
      padding-top:0 !important;                  /* to cancel out navigation.css */
    }

    /* navigation menu styling */
    nav{
      width: 100vw;
    }

    .lower-nav{
      height:auto;
      position: relative;
      display: block;
      line-height: 10px;
      top: 0;
      left:0;
      right: 0;                         /* to cancel out navigation.css */
    }

    .navi ul {
      list-style: none;
      background-color: #cecece;
      text-align: left;
      padding: 0;
      margin: 0;
    }

    .navi ul li{
      display: block;
    }

    .navi ul li:hover{
      /*display: none;*/
    }

    .navi ul ul{
      display: none;
    }

    .navi ul ul{
    	position: relative;
    	top:0px;
    	left:0px;
    }

    .navi ul ul li:hover{

    }

    /* Sub Menus */
    .navi li li {
      font-size: .8em;
    }

    nav ul ul ul li {
      position: relative;
      top: -8px;
      left: 0px;
    }

    /*******************************************/


    .hero-img-overlay {
      height: auto;
      /*position: relative;*/
      position: absolute;
      /*bottom: 0px;*/
      width: 100%;
      text-align: center;
      opacity: .8;
      padding: 5px 10px 0px 10px;
    }

    .img-overlay {
      height: 75px;
      position: absolute;
      bottom: 0px;
      /*width: 100%;*/
      width:100vw;
      text-align: center;
      /*opacity: .8;*/
      background:rgba(0,0,0,.5);
      text-shadow: none;
    }

    .hero-img-overlay-mid{
      /*height: 40vw;*/
      height: auto !important;
      position: absolute;
      bottom: 0 !important;
      width: 100vw;
      text-align: center;
      /*opacity: .8;*/
      text-shadow: none;
      padding: 10px 20px;
    }

    .hero-img-overlay p {
      font-size: 20px;
      color: #fff;
      margin-bottom: 0;
      font-style: italic;
    }

    .hero-heading-text {
      font-size: 36px !important;
      line-height: 45px;
      font-weight: bold;
      margin: 0px;
    }

    .hero-img-overlay-mid h2{
      font-size: 36px;
      line-height: 40px;
    }

    .centered-overlay {
      margin: 0 0 !important;
      opacity: .8;
      text-shadow: none;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;

    }

    .inset-header-container {
      margin: 10px 3.5%;
    }

    .inset-container {
      /*margin: 10px 10px;*/
      padding: 20px 20px;
      display: block !important;
    }

    .inset-container-mobile{
      margin: 0 0;
      width:100%;
    }

    .v-line{
      height: 100%;
      border-right: none;
    }

    .line{
      display: block;
      height: 1px;
      border-top: 2px solid #fdb813;
      margin: 1em 0;
      width: 100%;
    }

    .default-button {
      border: none;
      background: #fdb813;
      color: rgb(0,42,69);
      padding: 10px 20px;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      margin-right: 0 !important;
      width:75%;
      display: block;
    }

    .default-button:first-child{
      margin-bottom:30px !important;
    }

    .alert > .default-button{
      width:100%;
    }

    .alert > .default-button:first-child{
      margin-bottom:0px !important;
    }

    .marketing-feature{
      padding: 0 0 !important;
    }

    .marketing-feature h1 {
      color: #0055a5;
      font-size: 36px;
      text-align:center;
    }

    .text-container {
      margin: 25px 25px;
    }

    .slider-overlay{
      width:100%;
    }


    /* utilities */
    .hide-on-mobile{
      display:none !important;
    }

    .sm-flex{
      display:block !important;
    }

    /******************************************/

    /* footer stuff */
    footer{
      width:100vw;
    }

    .img-background {
      background: #cecece;
      background: url(/images/Fresh_cows_gradient_1920x615.webp);
      /*background-position: center center;*/
      background-position: top;
      background-repeat: no-repeat;
      min-width: 100vw !important;
      height:auto !important;
    }

    .center-image {
      text-align: center;
      /*margin: 23% 0;*/
      margin: 12% 0;
    }

    .fresh-text {
      padding-top: 0px !important;
    }

    .fresh-text h2 {
      color: #4e4e4e !important;
    }

    .fresh-text p{
      color: #4e4e4e !important;
    }

    .fresh-inset {
      width: 85% !important;
    }

    .footer-sub-heading {
      font-size: 24px;
      font-family: 'Helvetica Neue Medium' !important;
      color: #4e4e4e !important;
      text-align: center;
    }

    footer p {
      color: #fff !important;
      font-size: 18px;
      font-family: 'Helvetica Neue Light';
    }

    .vertical-line {
      border-right: none !important;
    }

    .bottom-inset {
      padding: 20px 20px !important;
    }

    .bottom-inset p{
      text-align: center;
    }

    .footer-sub-heading {
      font-size: 24px;
      font-family: 'Helvetica Neue Medium' !important;
      color: #fff !important;
    }

    .bottom-section-fresh h2 {
      color: #fff;
      bottom: 50px;
      left: 150px;
      position: relative;
      width:60%;
    }

    ul.bottom-list{
      text-align: center;
    }

    .events-container{
      /*width:50%;*/
      margin: 0;
    }

    /*************************************************/
  }

  /* Large devices (small desktops, 992px and down)  */
  @media (max-width: 991.98px) {
    body{
      max-width: 100% !important;
    }

    p{
      font-size: 1.25em !important;
    }

    iframe{
      max-width:100vw;
    }

    /* navigation menu styling */
    nav{
      width: 100vw;
    }

    .navi{
      /*padding-top:0;*/                  /* to cancel out navigation.css */
    }

    .lower-nav{
      height:auto;
      position: relative;
      display: block;
      line-height: 10px;
      top: 0;
      left:0;
    }

    .navi ul {
      list-style: none;
      background-color: #cecece;
      text-align: left;
      padding: 0;
      margin: 0;
    }

    .navi ul li{
      display: block;
    }

    .navi ul li:hover{
      /*display: none;*/
    }

    .navi ul ul{
      display: none;
    }

    .navi ul ul{
    	position: relative;
    	top:0px;
    	left:0px;
    }

    .navi ul ul li:hover{

    }

    /* Sub Menus */
    .navi li li {
      font-size: .8em;
    }

    nav ul ul ul li {
      position: relative;
      top: -8px;
      left: 0px;
    }

    nav ul ul li {
      height:auto;
      background:none;
    }

    .dropdown-menu{
      border: 0px solid rgba(0,0,0,.15) !important;
      border-radius: 0rem;
      padding: 0.5rem 5px !important;
    }

    .dropdown-menu .dropdown .dropdown-menu {
      margin: 0;
      border-width: 0px;
    }

    /*******************************************/

    /*  centered overlay */
    .centered-overlay{
      /*margin: 75px 30%;*/
      opacity: .8;
      text-shadow: none;
      position: relative;
      top: 0;                 /* added top, left and width */
      left: 0;
      max-width: 100%;
    }

    .inset-container{
      padding: 0 0 !important;
    }

    .inset-paragraph-container{
      margin: 0 20px;
    }

    .marketing-feature{
      padding: 25px 25px !important;
    }

    .form-inset {
      margin: 50px 20px;
    }

    .hero-img-overlay-mid {
      height: auto !important;
      position: relative;
      bottom: 0 !important;
      width: 100%;
      text-align: center;
      /*opacity: .8;*/
      text-shadow: none;
      padding: 10px 20px;
    }

    .hero-img-overlay-mid h2{
      line-height: 40px;
    }


    /* Utilities */
    .hide-on-mobile{
      display: none !important;
    }

    .sm-flex{
      display:flex;
    }

    .leftParagraphRightImage{
      padding: 10px;
    }

    .leftParagraphRightImageHeading{
      padding:10px;
      text-align: center;
    }

    .textCallout{
      padding:10px;
    }

    .feature-row:hover{
      transform: none;
    }

    /*.hide-on-desktop{
      display: block !important;
    }*/

    .fresh-text {
      padding-top: 0px !important;
    }

    .fresh-text h2 {
      color: #4e4e4e !important;
    }

    .fresh-text p{
      color: #4e4e4e !important;
    }

    .fresh-inset {
      width: 85% !important;
    }

    .footer-sub-heading {
      font-size: 24px;
      font-family: 'Helvetica Neue Medium' !important;
      color: #4e4e4e !important;
      text-align: center;
    }

    footer p {
      color: #fff !important;
      font-size: 18px;
      font-family: 'Helvetica Neue Light';
    }

    .vertical-line {
      border-right: none !important;
    }

    .bottom-inset {
      padding: 20px 20px !important;
    }

    .bottom-inset p{
      text-align: center;
    }

    .footer-sub-heading {
      font-size: 24px;
      font-family: 'Helvetica Neue Medium' !important;
      color: #fff !important;
    }

    .bottom-section-fresh h2 {
      color: #fff;
      bottom: 50px;
      left: 150px;
      position: relative;
      width:60%;
    }

    ul.bottom-list{
      text-align: center;
    }

    .events-container{
      max-width:90% !important;
      margin: 0 !important;
    }

  }

  /* Extra large devices (large desktops, 1200px and up) */
  /*
  @media (min-width: 1200px) {

  }
  */

  @media (max-width:1024px){
    blockquote::before{
      left: -50px;
    }
  }

  @media ( min-width: 320px ) and ( max-width: 576px ) {
    .hero-img-overlay-mid {
      height: auto !important;
      position: absolute;
      bottom: 0 !important;
      width: 100%;
      text-align: center;
      /*opacity: .8;*/
      text-shadow: none;
      padding: 10px 20px;
    }

    .card{
      width:100%;
    }

    .events-container{
      max-width:100% !important;
      margin: 0;
    }

    blockquote:before{
      z-index: -1;
      top:-1rem;
      left:0;
    }

    blockquote:after{
      right: -15px;
      top: -1rem;
      z-index: -1;
    }
  }

  /* A specific override for side-by-side images in small/medium width
to prevent them from stretching to full height. Added 2020-06-24 by MH*/
@media (min-width:768px) and (max-width:1365px) {
  .fit-image {
    min-height: auto !important; /* important needed to override bootstrap */
  }
}

@media (min-width:577px) and (max-width:700px){
  blockquote:before{
    z-index: -1;
    top:-1rem;
    left:0;
  }

  blockquote:after{
    right: -15px;
    top: -1rem;
    z-index: -1;
  }
}
